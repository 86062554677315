import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { isSafari } from "react-device-detect";

const ValidateBrowser = () => {
  useEffect(() => {
    const validateBrowser = () => {
      if (isSafari) {
        // navigate("/invalid-browser");
        return;
      }
    };
    validateBrowser();
  }, [navigate]);

  return <div></div>;
};

export default ValidateBrowser;
