import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Fab,
} from "@mui/material";

import { app } from "../utils/server/firebase";
import ValidateBrowser from "../assets/components/Catas/ValidateBrowser";
import "moment/locale/es";
import { getFirestore } from "firebase/firestore";
import Navbar from "../assets/components/Navbar";
import Footer from "../assets/components/Footer";
import Seo from "../assets/components/seo";
import PersonalInformation from "../assets/components/Catas/PersonalInformation";
import EventInformation from "../assets/components/Catas/EventInformation";
import FinalQuestions from "../assets/components/Catas/FinalQuestions";
import Congratulations from "../assets/components/Catas/Congratulations";

const db = getFirestore(app);

const Reservaciones = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [data, setData] = useState();

  const nextStep = () => setActiveStep(activeStep + 1);
  const prevStep = () => setActiveStep(activeStep - 1);

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo
        title="Catas - Festival del Chocolate"
        description="Reserva tus catas para el Festival del Chocolate"
      />
      <Navbar />
      <ValidateBrowser />

      <Grid container justifyContent="center" paddingY={12} minHeight="60vh">
        <Grid item container maxWidth="sm" justifyContent="center" px={4}>
          <Grid item xs={12}>
            <Typography variant="h1" textAlign="center">
              Reservación de catas
            </Typography>
          </Grid>
          <Grid item marginY={2}>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step>
                <StepLabel>Ingresa tus datos</StepLabel>
              </Step>
              <Step>
                <StepLabel>Selecciona tu cata</StepLabel>
              </Step>
              <Step>
                <StepLabel>Más información</StepLabel>
              </Step>
              <Step>
                <StepLabel>¡Cata reservada!</StepLabel>
              </Step>
            </Stepper>
          </Grid>

          {activeStep === 0 ? (
            <PersonalInformation
              nextStep={nextStep}
              data={data}
              setData={setData}
              db={db}
            />
          ) : null}
          {activeStep === 1 ? (
            <EventInformation
              nextStep={nextStep}
              prevStep={prevStep}
              data={data}
              setData={setData}
              db={db}
            />
          ) : null}
          {activeStep === 2 ? (
            <FinalQuestions
              prevStep={prevStep}
              nextStep={nextStep}
              data={data}
              setData={setData}
              db={db}
            />
          ) : null}
          {activeStep === 3 ? <Congratulations data={data} /> : null}
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default Reservaciones;
