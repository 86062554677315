import { useState } from "react"; // Importa useState para manejar el estado
import { addDoc, collection } from "@firebase/firestore";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { HOSTES } from "../../../../utils/constants";
import emailjs from "@emailjs/browser";
import moment from "moment";

const FinalQuestions = ({ prevStep, nextStep, data, setData, db }) => {
  const [loading, setLoading] = useState(false); // Estado para controlar la carga

  const handleNext = async () => {
    setLoading(true); // Activa el estado de carga
    try {
      const docsRef = collection(db, "reservacionCatas");
      await addDoc(docsRef, {
        evento: data.evento,
        nombre: data.nombre,
        telefono: data.telefono,
        facebook: data?.facebook || "",
        instagram: data?.instagram || "",
        twitter: data?.twitter || "",
        promotion: data?.promotion || false,
        fechaRegistro: new Date(),
        email: data.email,
        inicio: data.inicio,
        salon: data.salon,
        used: false,
      });

      await emailjs.send(
        "service_rb3ty97",
        "template_zgc1544",
        {
          evento: data?.evento,
          nombre: data?.nombre,
          inicio: `${moment(data.inicio.toDate()).format(
            "dddd D MMMM, hh:mm a"
          )}`,
          salon: data?.salon,
          email: data?.email,
        },
        "zedoThcsPAXIA-LIv"
      );

      nextStep();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Desactiva el estado de carga después de intentar
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel>¿Cómo te enteraste del festival del chocolate?</FormLabel>
          <RadioGroup
            name="host"
            value={data?.host || ""}
            onChange={(e) => setData({ ...data, host: e.target.value })}
          >
            {HOSTES.sort((a, b) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            }).map((item, key) => (
              <FormControlLabel
                key={key}
                value={item}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={data?.promotion || false}
              onChange={(e) =>
                setData({ ...data, promotion: e.target.checked })
              }
            />
          }
          label="Quiero recibir promociones y actualizaciones via correo electrónico."
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          sx={{ marginRight: 2 }}
          variant="outlined"
          color="primary"
          onClick={prevStep}
        >
          Atrás
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleNext}
          disabled={loading} // Deshabilita el botón mientras se está cargando
        >
          {loading ? "Finalizando..." : "Finalizar"}{" "}
          {/* Cambia el texto del botón mientras se carga */}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FinalQuestions;
