import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import React, { useRef } from "react";
import { ComponentToPrint } from "../ComponentToPrint";
import ReactToPrint from "react-to-print";
import { Margin, usePDF } from "react-to-pdf";

const Congratulations = ({ data }) => {
  const componentRef = useRef();
  const route = "https://festivaldelchocolate.mx/validate";

  const { toPDF, targetRef } = usePDF({
    filename: "programa.pdf",
    page: {
      margin: Margin.MEDIUM,
      format: "letter",
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>
          Gracias por registrarte, esperamos tu asistencia!
        </Typography>
      </Grid>

      <Grid item container xs={12} justifyContent="center">
        <Grid
          item
          xs
          container
          spacing={3}
          direction="column"
          justifyContent="center"
          ref={targetRef}
        >
          <Grid item>
            <Typography
              color="primary"
              variant="h5"
              textAlign="center"
              fontWeight={600}
            >
              🎉 Pase de Acceso a Catas 🍫
            </Typography>
          </Grid>
          <Grid item textAlign="justify">
            <Typography>
              Hola, <span style={{ fontWeight: "bold" }}>{data.nombre}</span>,
              ¡Te has registrado para disfrutar del evento{" "}
              <span style={{ fontWeight: "bold" }}>{data.evento}</span>!, la
              cual se llevará a cabo en el horario que seleccionaste
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography>
              Por favor, al llegar muestra tu pase de acceso.
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography fontWeight={600}>🗓 Fecha y Hora del Evento:</Typography>
            <Typography>
              {`${moment(data.inicio.toDate()).format("dddd D MMMM, hh:mm a")}`}
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            {
              <QRCodeSVG
                value={`${route}/?m=${data.email}?ev=${data.evento}`}
              />
            }
          </Grid>
          <Grid item>
            <Typography textAlign="center">
              ¡Nos vemos pronto para celebrar el chocolate en su máxima
              expresión! 🎊
            </Typography>
          </Grid>
          <Grid item textAlign="justify">
            <Typography color="GrayText">
              Está hoja es tu pase de acceso a la Cata seleccionado en el
              horario que escogiste previamente. En caso de perderlo, podrás
              accesar con el que fue enviado a tu correo electrónico al momento
              de registrarte.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" padding={5}>
          <Button color="primary" variant="contained" onClick={toPDF}>
            Descargar
          </Button>
        </Grid>

        <div style={{ display: "none" }}>
          <ComponentToPrint
            ref={componentRef}
            data={data}
            qr={
              <QRCodeSVG
                value={`${route}/?m=${data.email}?ev=${data.evento}`}
              />
            }
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Congratulations;
